import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

export const encryptData = (data: any) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), 'gab_secret').toString()
  } catch (error) {
    console.log(error)
    return ''
  }
}

export const decryptData = (data: any) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, 'gab_secret')
    const stringArray = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return stringArray
  } catch (error) {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      const neededAttributes = {
        // Here you pass the same attributes that were used when the cookie was created
        // and are required when removing the cookie
      }
      Cookies.remove(cookieName, neededAttributes)
    })
  }
}
