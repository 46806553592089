import '@fontsource/montserrat'
import 'react-toastify/dist/ReactToastify.css'
import './src/styles/global.css'
import { Provider } from 'react-redux'
import { Auth0Provider } from '@auth0/auth0-react'
import { setupStore } from './src/store'
import { navigate } from 'gatsby'

const store = setupStore()

const auth0Domain = process.env.AUTH0_DOMAIN
const auth0ClientId = process.env.AUTH0_CLIENT_ID

const onRedirectCallback = appState => {
  // Use Gatsby's navigate method to replace the url
  navigate(appState?.returnTo || '/', { replace: true })
}

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      audience={`https://${auth0Domain}/api/v2/`}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>{element}</Provider>
    </Auth0Provider>
  )
}
