// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-child-protection-policy-tsx": () => import("./../../../src/pages/child-protection-policy.tsx" /* webpackChunkName: "component---src-pages-child-protection-policy-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurance-policy-tsx": () => import("./../../../src/pages/insurance-policy.tsx" /* webpackChunkName: "component---src-pages-insurance-policy-tsx" */),
  "component---src-pages-kids-korner-tsx": () => import("./../../../src/pages/kids-korner.tsx" /* webpackChunkName: "component---src-pages-kids-korner-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-association-index-tsx": () => import("./../../../src/pages/register-association/index.tsx" /* webpackChunkName: "component---src-pages-register-association-index-tsx" */),
  "component---src-pages-register-coach-details-tsx": () => import("./../../../src/pages/register/coach/details.tsx" /* webpackChunkName: "component---src-pages-register-coach-details-tsx" */),
  "component---src-pages-register-coach-headshot-tsx": () => import("./../../../src/pages/register/coach/headshot.tsx" /* webpackChunkName: "component---src-pages-register-coach-headshot-tsx" */),
  "component---src-pages-register-coach-payment-tsx": () => import("./../../../src/pages/register/coach/payment.tsx" /* webpackChunkName: "component---src-pages-register-coach-payment-tsx" */),
  "component---src-pages-register-coach-profile-tsx": () => import("./../../../src/pages/register/coach/profile.tsx" /* webpackChunkName: "component---src-pages-register-coach-profile-tsx" */),
  "component---src-pages-register-coach-review-tsx": () => import("./../../../src/pages/register/coach/review.tsx" /* webpackChunkName: "component---src-pages-register-coach-review-tsx" */),
  "component---src-pages-register-coach-success-tsx": () => import("./../../../src/pages/register/coach/success.tsx" /* webpackChunkName: "component---src-pages-register-coach-success-tsx" */),
  "component---src-pages-register-coach-venue-tsx": () => import("./../../../src/pages/register/coach/venue.tsx" /* webpackChunkName: "component---src-pages-register-coach-venue-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-player-details-tsx": () => import("./../../../src/pages/register/player/details.tsx" /* webpackChunkName: "component---src-pages-register-player-details-tsx" */),
  "component---src-pages-register-player-headshot-tsx": () => import("./../../../src/pages/register/player/headshot.tsx" /* webpackChunkName: "component---src-pages-register-player-headshot-tsx" */),
  "component---src-pages-register-player-order-uniform-item-tsx": () => import("./../../../src/pages/register/player/order-uniform-item.tsx" /* webpackChunkName: "component---src-pages-register-player-order-uniform-item-tsx" */),
  "component---src-pages-register-player-order-uniform-tsx": () => import("./../../../src/pages/register/player/order-uniform.tsx" /* webpackChunkName: "component---src-pages-register-player-order-uniform-tsx" */),
  "component---src-pages-register-player-payment-tsx": () => import("./../../../src/pages/register/player/payment.tsx" /* webpackChunkName: "component---src-pages-register-player-payment-tsx" */),
  "component---src-pages-register-player-profile-tsx": () => import("./../../../src/pages/register/player/profile.tsx" /* webpackChunkName: "component---src-pages-register-player-profile-tsx" */),
  "component---src-pages-register-player-review-tsx": () => import("./../../../src/pages/register/player/review.tsx" /* webpackChunkName: "component---src-pages-register-player-review-tsx" */),
  "component---src-pages-register-player-success-tsx": () => import("./../../../src/pages/register/player/success.tsx" /* webpackChunkName: "component---src-pages-register-player-success-tsx" */),
  "component---src-pages-register-player-venue-tsx": () => import("./../../../src/pages/register/player/venue.tsx" /* webpackChunkName: "component---src-pages-register-player-venue-tsx" */),
  "component---src-pages-register-referee-details-tsx": () => import("./../../../src/pages/register/referee/details.tsx" /* webpackChunkName: "component---src-pages-register-referee-details-tsx" */),
  "component---src-pages-register-referee-headshot-tsx": () => import("./../../../src/pages/register/referee/headshot.tsx" /* webpackChunkName: "component---src-pages-register-referee-headshot-tsx" */),
  "component---src-pages-register-referee-order-uniform-item-tsx": () => import("./../../../src/pages/register/referee/order-uniform-item.tsx" /* webpackChunkName: "component---src-pages-register-referee-order-uniform-item-tsx" */),
  "component---src-pages-register-referee-order-uniform-tsx": () => import("./../../../src/pages/register/referee/order-uniform.tsx" /* webpackChunkName: "component---src-pages-register-referee-order-uniform-tsx" */),
  "component---src-pages-register-referee-payment-tsx": () => import("./../../../src/pages/register/referee/payment.tsx" /* webpackChunkName: "component---src-pages-register-referee-payment-tsx" */),
  "component---src-pages-register-referee-profile-tsx": () => import("./../../../src/pages/register/referee/profile.tsx" /* webpackChunkName: "component---src-pages-register-referee-profile-tsx" */),
  "component---src-pages-register-referee-review-tsx": () => import("./../../../src/pages/register/referee/review.tsx" /* webpackChunkName: "component---src-pages-register-referee-review-tsx" */),
  "component---src-pages-register-referee-success-tsx": () => import("./../../../src/pages/register/referee/success.tsx" /* webpackChunkName: "component---src-pages-register-referee-success-tsx" */),
  "component---src-pages-register-referee-venue-tsx": () => import("./../../../src/pages/register/referee/venue.tsx" /* webpackChunkName: "component---src-pages-register-referee-venue-tsx" */),
  "component---src-pages-register-team-details-tsx": () => import("./../../../src/pages/register/team/details.tsx" /* webpackChunkName: "component---src-pages-register-team-details-tsx" */),
  "component---src-pages-register-team-invite-tsx": () => import("./../../../src/pages/register/team/invite.tsx" /* webpackChunkName: "component---src-pages-register-team-invite-tsx" */),
  "component---src-pages-register-team-review-tsx": () => import("./../../../src/pages/register/team/review.tsx" /* webpackChunkName: "component---src-pages-register-team-review-tsx" */),
  "component---src-pages-register-team-success-tsx": () => import("./../../../src/pages/register/team/success.tsx" /* webpackChunkName: "component---src-pages-register-team-success-tsx" */),
  "component---src-pages-register-team-uniform-design-tsx": () => import("./../../../src/pages/register/team/uniform-design.tsx" /* webpackChunkName: "component---src-pages-register-team-uniform-design-tsx" */),
  "component---src-pages-register-team-venue-tsx": () => import("./../../../src/pages/register/team/venue.tsx" /* webpackChunkName: "component---src-pages-register-team-venue-tsx" */),
  "component---src-pages-run-a-competition-tsx": () => import("./../../../src/pages/run-a-competition.tsx" /* webpackChunkName: "component---src-pages-run-a-competition-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-test-headers-tsx": () => import("./../../../src/pages/test-headers.tsx" /* webpackChunkName: "component---src-pages-test-headers-tsx" */),
  "component---src-pages-welfare-policy-tsx": () => import("./../../../src/pages/welfare-policy.tsx" /* webpackChunkName: "component---src-pages-welfare-policy-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who-we-are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

