import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { decryptData } from 'manage-tritag/utils/crypto'

const mainApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.API,
    prepareHeaders: headers => {
      const token = decryptData(localStorage.getItem('mahiwagangTanzan'))

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('x-access-token', token)
        headers.set('MahanginAko', token)
      }

      return headers
    },
  }),
  tagTypes: [
    'abn-lookup',
    'association',
    'associations',
    'cart',
    'cart-totals',
    'competition',
    'competitions',
    'division',
    'divisions',
    'ladder',
    'ladders',
    'match',
    'matches',
    'participant',
    'participants',
    'roles',
    'season',
    'seasons',
    'sub-master',
    'sub-masters',
    'team',
    'teams',
    'uniform',
    'venue',
    'venues',
  ],
  endpoints: () => ({}),
})

export default mainApi
